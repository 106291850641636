import { List, Typography } from "@material-ui/core";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";
import { useAppSelector } from "../redux/Store";
import { GlobalStateContext } from "./screens/HomeScreen";

const BusinessHours = () => {
  const region = useAppSelector(state => state.region);
  const momentWithTimezone = inp => {
    const m = inp ? moment(inp) : moment();
    if (region?.timezone) {
      m.tz(region.timezone);
    } else {
      // Todo log error into crashalitycs
    }
    return m;
  };
  const { businessDetails } = useContext(GlobalStateContext);
  const { calendarOption } = useContext(GlobalStateContext);
  const businessHours = orderByWeekStartDay(businessDetails?.value?.businessHours, calendarOption?.value?.weekStartDay ?? 0);
  const { t } = useTranslation();
  const { CardCtx, foregroundColorCtx } = useContext(StyleContext);
  const currentDayStyle = day => momentWithTimezone().day().toString() === day.toString() && { color: foregroundColorCtx };
  const labelStyle = day => ({ marginBottom: -5, ...currentDayStyle(day) });
  return (
    <CardCtx title="BusinessHours" avatar={<QueryBuilderIcon />}>
      <List component="nav" aria-label="contacts">
        {businessHours.map(b => (
          <div
            key={b.day}
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              justifyContent: "space-between",
              marginBottom: 15,
            }}>
            <Typography key={b.day} style={{ display: "flex", ...currentDayStyle(b.day) }}>
              {momentWithTimezone().day(b.day).format("dddd")}
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              {b.enabled ? (
                b.hours.map(h => (
                  <label key={h.startTime} style={labelStyle(b.day)}>
                    {`${momentWithTimezone(h.startTime).format("HH:mm")} - ${momentWithTimezone(h.endTime).format("HH:mm")}`}
                  </label>
                ))
              ) : (
                <label key={b.day} style={labelStyle(b.day)}>
                  {t("Closed")}
                </label>
              )}
            </div>
          </div>
        ))}
      </List>
    </CardCtx>
  );
};
// Order the week day by the start day of a week depending on location
const orderByWeekStartDay = (businessHours, weekStartDay) => {
  let weekDayLength = 6;
  const result = [];
  while (weekDayLength >= 0) {
    for (let i = weekStartDay; i < businessHours?.length && weekDayLength >= 0; i++) {
      result.push(businessHours.find(b => b.day === i));
      weekDayLength--;
    }
    // Ensure to loop on every day when we start after 0
    weekStartDay = 0;
  }
  return result;
};
export default BusinessHours;
