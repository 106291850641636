import { IconButton } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import { GlobalStateContext } from "./screens/HomeScreen";
// Carousel
const CustomCarousel = () => {
  const { businessDetails } = useContext(GlobalStateContext);
  const HasMultiplePhotos = () => businessDetails?.value?.photosUrls?.filter(p => p).length > 1;
  const { t } = useTranslation();
  const lightTransparent = "rgba(0, 0, 0, 0.5)";
  const darkTransparent = "rgba(0, 0, 0, 0.8)";
  const indicatorSize = { height: 10, width: 10 };
  const arrowStyle = isRight => ({
    color: "white",
    backgroundColor: lightTransparent,
    position: "absolute",
    zIndex: 1,
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: "50%",
    right: isRight && 5,
    left: !isRight && 5,
    "&:hover": {
      backgroundColor: darkTransparent,
    },
  });

  const GetAltName = index => {
    switch (index) {
      case 0:
        return t("FirstPhoto");
      case 1:
        return t("SecondPhoto");
      case 2:
        return t("ThirdPhoto");
      default:
        return t("Photo");
    }
  };

  // Render carousel
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      interval={7000}
      transitionTime={1000}
      renderArrowPrev={clickHandler =>
        HasMultiplePhotos() && (
          <IconButton sx={arrowStyle(false)} onClick={clickHandler}>
            <NavigateBeforeIcon />
          </IconButton>
        )
      }
      renderArrowNext={clickHandler =>
        HasMultiplePhotos() && (
          <IconButton sx={arrowStyle(true)} onClick={clickHandler}>
            <NavigateNextIcon />
          </IconButton>
        )
      }
      renderIndicator={(clickHandler, isSelected) =>
        HasMultiplePhotos() && (
          <IconButton
            sx={{
              ...indicatorSize,
              color: isSelected ? "white" : "rgba(0, 0, 0, 0)",
              backgroundColor: lightTransparent,
              marginRight: 1,
              "&:hover": {
                backgroundColor: darkTransparent,
              },
            }}
            onClick={clickHandler}>
            <FiberManualRecordIcon sx={indicatorSize} />
          </IconButton>
        )
      }>
      {businessDetails?.value?.photosUrls
        ?.filter(p => p)
        .map((photoUrl, index) => (
          <div>
            <img style={{ height: 480, objectFit: "cover" }} src={photoUrl} alt={GetAltName(index)} />
          </div>
        ))}
    </Carousel>
  );
};

export default CustomCarousel;
