import Typography from "@material-ui/core/Typography";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { useContext } from "react";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";
import { GlobalStateContext } from "./screens/HomeScreen";

const About = () => {
  const { businessDetails } = useContext(GlobalStateContext);
  const { CardCtx } = useContext(StyleContext);
  return (
    <CardCtx title="About" avatar={<DescriptionOutlinedIcon />}>
      {/* pre-line : break line if description contain line break. break-word : break line if the sentence exceeds the card */}
      <Typography variant="body2" whiteSpace="pre-line" style={{ wordWrap: "break-word" }}>
        {businessDetails?.value?.description}
      </Typography>
    </CardCtx>
  );
};
export default About;
