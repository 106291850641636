import { CircularProgress } from "@material-ui/core";

const LoadingScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}>
      <CircularProgress color="primary" />
    </div>
  );
};
export default LoadingScreen;
