import { CollectionPath } from "../../api/firebase/Types/CollectionPath";
import { ICustomer } from "../interfaces/ICustomer";
import { Document } from "./Document";
export class Customer extends Document implements ICustomer {
  name: string;
  nameLowerCase: string;
  countryCode: string;
  phoneNumber?: number;
  email?: string;
  address?: string;
  note?: string;
  id?: string;

  constructor(name: string, countryCode: string, phoneNumber?: number, email?: string, address?: string, note?: string, id?: string) {
    super();
    this.name = name;
    this.nameLowerCase = name?.toLowerCase();
    this.countryCode = countryCode;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.address = address;
    this.note = note;
    this.id = id;
  }
  getCollectionPath(): CollectionPath {
    return CollectionPath.Customer;
  }
}
