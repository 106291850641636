export enum CollectionPath {
  User = "user",
  Appointment = "appointment",
  Customer = "customer",
  Service = "service",
  Message = "message",
  Option = "option",
  BusinessDetails = "businessDetails",
  Calendar = "calendar",
  Region = "region",
  Notification = "notification",
}
