import { CollectionPath } from "../../api/firebase/Types/CollectionPath";
import { DateTimeArrayConverter, DateTimeConverter } from "../Decorators";
import { IAppointment } from "../interfaces/IAppointment";
import { IServiceForAppointment } from "../interfaces/IServiceForAppointment";
import { Document } from "./Document";
export class Appointment extends Document implements IAppointment {
  @DateTimeConverter
  startDateTime: number;
  @DateTimeConverter
  endDateTime: number;
  customerIds: string[];
  services?: IServiceForAppointment[];
  messageIds?: string[];
  @DateTimeArrayConverter
  messageDates?: Date[];
  messageContents?: string[];
  price?: number;
  note?: string;
  id?: string;

  constructor(
    startDateTime: number,
    endDateTime: number,
    customerIds: string[],
    services?: IServiceForAppointment[],
    messageIds?: string[],
    messageDates?: Date[],
    messageContents?: string[],
    price?: number,
    note?: string,
    id?: string
  ) {
    super();
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.customerIds = customerIds;
    this.services = services;
    this.messageIds = messageIds;
    this.messageDates = messageDates;
    this.messageContents = messageContents;
    this.price = price;
    this.note = note;
    this.id = id;
  }
  getCollectionPath(): CollectionPath {
    return CollectionPath.Appointment;
  }
}
