import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// Default language if language detector not working
export const defaultLanguage = "en";
// Init i18n
i18n
  .use(Backend) // load translations using http
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    backend: {
      loadPath: `./locales/{{lng}}.json`, // public folder
    },
    fallbackLng: defaultLanguage, // fallback language is english
    debug: true, // helps finding issues with loading not working
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
export default i18n;
