import { Typography } from "@material-ui/core";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";

const Address = ({ value }) => {
  const { t } = useTranslation();
  const { CardCtx } = useContext(StyleContext);
  const apiKey = "AIzaSyBs70f4nh3DEgPzTUtb9G_3QWxZpxbgHNU";
  return (
    <CardCtx
      title="Address"
      avatar={<LocationOnOutlinedIcon />}
      cardContentStyle={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}>
      <Typography variant="body2" marginBottom={1}>
        {value.name}
      </Typography>
      {(value.placeId || value.geoPoint) && (
        <iframe
          title={t("Address")}
          height="100%"
          width="100%"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={`https://www.google.com/maps/embed/v1/place?q=${
            value.placeId ? "place_id:" + value.placeId : value.geoPoint.latitude + "," + value.geoPoint.longitude
          }&key=${apiKey}`}
        />
      )}
    </CardCtx>
  );
};
export default Address;
