import { Box } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";

export const DisplayCustomerInformation = (customers, t) =>
  customers?.length > 1
    ? t("MultipleCustomers")
    : customers?.length === 1 && customers[0]
    ? `${customers[0].name} | ${customers[0].email} | +${customers[0].phoneNumber}`
    : t("NoCustomer");
// Customer information
const CustomerInformation = () => {
  const { t } = useTranslation();
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { mb: 1, mt: 1 },
      }}
      noValidate
      // autoComplete="off"
    >
      <Field required fullWidth variant="outlined" name="name" label={t("Name")} component={TextField} />
      <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <div style={{ display: "flex", flex: 1, marginRight: 10, width: "100%" }}>
          <Field required fullWidth variant="outlined" name="email" label={t("Email")} component={TextField} />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            marginLeft: 10,
            justifyContent: "flex-start",
          }}>
          <Field required variant="outlined" name="phoneNumber" label={t("PhoneNumberRequired")} component={PhoneNumberField} />
        </div>
      </div>
      <Field multiline fullWidth rows={5} variant="outlined" name="note" label={t("NoteOptional")} component={TextField} />
    </Box>
  );
};
// Phone number field customized
const PhoneNumberField = props => {
  const { t } = useTranslation();
  const { foregroundColorCtx } = useContext(StyleContext);
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
  return (
    <FormControl style={{ display: "flex", flex: 1, marginTop: 8, marginBottom: 8 }}>
      <PhoneInput
        enableSearch
        searchPlaceholder={t("Search")}
        specialLabel={props.label}
        inputStyle={{ width: "100%", height: 56 }}
        containerStyle={{
          color: isPhoneNumberFocused ? foregroundColorCtx : "#666666",
        }}
        country={props.form.initialValues.countryCode}
        onFocus={() => setIsPhoneNumberFocused(true)}
        onBlur={() => {
          setIsPhoneNumberFocused(false);
          props.form.setFieldTouched(props.field.name, true);
        }}
        value={props.field.value}
        onChange={(value, countryData) => {
          props.form.setFieldValue(props.field.name, value);
          props.form.setFieldValue("countryCode", countryData.countryCode);
        }}
      />
      {props.form.touched.phoneNumber && (
        <FormHelperText error id="component-helper-text">
          {props.form.errors.phoneNumber}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default CustomerInformation;
