import { IServiceForAppointment } from "../interfaces/IServiceForAppointment";

export class ServiceForAppointment implements IServiceForAppointment {
  id: string;
  quantity: number;

  constructor(id: string, quantity: number) {
    this.id = id;
    this.quantity = quantity;
  }
}
