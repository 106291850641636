export const IS_LOADING = "IS_LOADING";
export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_BUSINESS_DETAILS = "UPDATE_BUSINESS_DETAILS";
export const UPDATE_APPOINTMENTS = "UPDATE_APPOINTMENTS";
export const UPDATE_SERVICES = "UPDATE_SERVICES";
export const UPDATE_DEFAULT_MESSAGES = "UPDATE_DEFAULT_MESSAGES";
export const UPDATE_CALENDAR_OPTION = "UPDATE_CALENDAR_OPTION";
export const UPDATE_REGION = "UPDATE_REGION";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
