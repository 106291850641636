import { Grid } from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { createStyles, makeStyles } from "@material-ui/styles";
import { FormikProps } from "formik";
import moment from "moment";
import "moment-duration-format";
import "moment-timezone";
import "moment/min/locales";
import { GetCurrentLocale } from "../../helpers/Helper";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { nameof } from "ts-simple-nameof";
import * as Yup from "yup";
import { object } from "yup";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  addDocument,
  findCustomers,
  getAllServices,
  getBusinessDetails,
  getCalendarOptions,
  GetCustomer,
  getDefaultMessages,
  getNotification,
  getRegion,
  getUserDetails,
  getUserIdFromBookingUrl,
  listenAppointments,
  updateDocument,
} from "../../api/firebase/FirestoreService";
import { CollectionPath } from "../../api/firebase/Types/CollectionPath";
import { GetInstanceOf } from "../../helpers/Helper";
import { Appointment } from "../../model/classes/Appointment";
import { BusinessDetails } from "../../model/classes/BusinessDetails";
import { CalendarOption } from "../../model/classes/CalendarOption";
import { Customer } from "../../model/classes/Customer";
import { Message } from "../../model/classes/Message";
import { Region } from "../../model/classes/Region";
import { ServiceForAppointment } from "../../model/classes/ServiceForAppointment";
import { ServiceWithQuantity } from "../../model/classes/ServiceWithQuantity";
import { IGlobalStateContext } from "../../model/interfaces/contexts/IGlobalStateContext";
import { IAppointment } from "../../model/interfaces/IAppointment";
import { ICustomer } from "../../model/interfaces/ICustomer";
import { updateLocalData } from "../../redux/actions/LocalDataAction";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/Store";
import {
  IS_LOADING,
  UPDATE_APPOINTMENTS,
  UPDATE_BUSINESS_DETAILS,
  UPDATE_CALENDAR_OPTION,
  UPDATE_DEFAULT_MESSAGES,
  UPDATE_NOTIFICATION,
  UPDATE_REGION,
  UPDATE_SERVICES,
  UPDATE_USER_DETAILS,
  UPDATE_USER_ID,
} from "../../redux/types/LocalDataType";
import { UrlParams } from "../../model/types/UrlParams";
import About from "../About";
import AdditionalInformation from "../AdditionalInformation";
import Address from "../Address";
import BusinessHours from "../BusinessHours";
import Calendar from "../Calendar";
import Confirmation from "../Confirmation";
import Contact from "../Contact";
import CustomCarousel from "../CustomCarousel";
import CustomerInformation, { DisplayCustomerInformation } from "../CustomerInformation";
import Footer from "../Footer";
import LogoAndBusinessName from "../LogoAndBusinessName";
import {
  AtLeastOneServiceSelected,
  DisplaySelectedServices,
  GetSelectedServices,
  GetSelectedServicesDuration,
  GetSelectedServicesPrice,
  Service,
} from "../Service";
import FormikStep from "../stepper/FormikStep";
import FormikStepper from "../stepper/FormikStepper";
import LoadingScreen from "./LoadingScreen";
import NotFoundScreen from "./NotFoundScreen";
import { getMessageDatesAndContents } from "previsy-framework/build/api/firebase/FirestoreHelper";
import { StyleContext, StyleProvider, theme } from "../../model/interfaces/contexts/StyleContext";
import { ChildrenType } from "../../model/types/ChildrenType";
import { UserDetails } from "../../model/classes/UserDetails";
import { GetPushAppointmentData, PushAppointmentType } from "../../helpers/PushNotification";
import { Notification } from "../../model/classes/Notification";
const useStyles = makeStyles(() =>
  createStyles({
    serviceList: { overflow: "auto", maxHeight: 375 },
  })
);

export const GlobalStateContext = createContext<IGlobalStateContext>({});
// Home screen
const HomeScreen = () => {
  const history = useHistory();
  const { search } = useLocation();
  // TODO not working for IE11
  const searchParams = new URLSearchParams(search);
  const appointmentId = searchParams.get("appointmentId");
  const isLoading = useAppSelector((state: RootState) => state.isLoading);
  const region = useAppSelector((state: RootState) => state.region);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { urlName } = useParams<UrlParams>();
  const mounted = useRef<boolean>();
  const customerInformationFormRef = useRef<FormikProps<{}>>(null);
  const serviceFormRef = useRef<FormikProps<{}>>(null);
  const calendarFormRef = useRef<FormikProps<{}>>(null);
  const [submitted, setSubmitted] = useState(false);
  const [userId, setUserId] = useState<string>("");
  const [bookedCustomers, setBookedCustomers] = useState<Customer[]>();
  const [bookedAppointment, setBookedAppointment] = useState<Appointment>();
  const [services, setServices] = useState<ServiceWithQuantity[]>([]);
  const [defaultMessages, setDefaultMessages] = useState<Message[]>([]);
  const [userDetails, setUserDetails] = useState<UserDetails>({} as UserDetails);
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails>({} as BusinessDetails);
  const [calendarOption, setCalendarOption] = useState<CalendarOption>({} as CalendarOption);
  const [notification, setNotification] = useState<Notification>({} as Notification);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const classes = useStyles();
  const currentLocale = GetCurrentLocale();
  const hasBusinessContact = () =>
    businessDetails?.phoneNumber ||
    businessDetails?.email ||
    businessDetails?.website ||
    businessDetails?.facebook ||
    businessDetails?.instagram ||
    businessDetails?.twitter ||
    businessDetails?.snapchat ||
    businessDetails?.tiktok;

  useEffect(() => {
    // Get data function
    const getData = async () => {
      // Get user Id from url
      const tempUserId = await getUserIdFromBookingUrl(urlName);
      setUserId(tempUserId);
      dispatch(updateLocalData({ type: UPDATE_USER_ID, payload: tempUserId }));
      // Get services, messages, business details...
      if (tempUserId) {
        const tempServices = await getAllServices();
        setServices(tempServices);
        dispatch(updateLocalData({ type: UPDATE_SERVICES, payload: tempServices }));

        const tempDefaultMessages = await getDefaultMessages();
        setDefaultMessages(tempDefaultMessages);
        dispatch(updateLocalData({ type: UPDATE_DEFAULT_MESSAGES, payload: tempDefaultMessages }));

        const tempUserDetails = (await getUserDetails()) as UserDetails;
        dispatch(updateLocalData({ type: UPDATE_USER_DETAILS, payload: tempUserDetails }));
        setUserDetails(tempUserDetails);

        const tempBusinessDetails = (await getBusinessDetails()) as BusinessDetails;
        setBusinessDetails(tempBusinessDetails);
        dispatch(updateLocalData({ type: UPDATE_BUSINESS_DETAILS, payload: tempBusinessDetails }));

        const tempCalendarOption = (await getCalendarOptions()) as CalendarOption;
        setCalendarOption(tempCalendarOption);
        dispatch(updateLocalData({ type: UPDATE_CALENDAR_OPTION, payload: tempCalendarOption }));

        const tempRegion = (await getRegion()) as Region;
        dispatch(updateLocalData({ type: UPDATE_REGION, payload: tempRegion }));
        if (tempRegion?.timezone) {
          moment.tz.setDefault(tempRegion.timezone);
        } else {
          // Todo log error into crashalitycs
        }
        const tempNotification = (await getNotification()) as Notification;
        setNotification(tempNotification);
        dispatch(updateLocalData({ type: UPDATE_NOTIFICATION, payload: tempNotification }));
      }
      return tempUserId;
    };
    let stopListenAppointments: { (): void } | null;
    if (!mounted.current) {
      // ComponentDidMount
      mounted.current = true;
      getData().then(userId => {
        if (userId) {
          // Separate listeners from get data async function to keep the original ref to unsubscribe the right listener
          stopListenAppointments = listenAppointments(async a => {
            setAppointments(a);
            dispatch(updateLocalData({ type: UPDATE_APPOINTMENTS, payload: a }));
          });
        } else {
          dispatch(updateLocalData({ type: IS_LOADING, payload: false }));
        }
      });
    } else {
      // ComponentDidUpdate
      const tempBookedAppointment = appointments?.find(a => a.id === appointmentId);
      setBookedAppointment(tempBookedAppointment);
      if (tempBookedAppointment?.customerIds?.length === 1 && !bookedCustomers) {
        GetCustomer(tempBookedAppointment?.customerIds[0]).then(c => {
          setBookedCustomers(c ? [c] : []);
          dispatch(updateLocalData({ type: IS_LOADING, payload: false }));
        });
      } else {
        dispatch(updateLocalData({ type: IS_LOADING, payload: false }));
      }
    }
    // ComponentDidMount AND ComponentDidUpdate
    return () => {
      // componentWillUnmount
      // TODO if we stop now, listen will not working anymore
      // stopListenAppointments?.();
    };
  }, [appointmentId, appointments, bookedCustomers, dispatch, urlName]);

  // Convert to appointment services. Cast "as" not working with typescript
  const ConvertToAppointmentServices = (services: ServiceWithQuantity[]) => {
    return services.map(s => Object.assign({}, new ServiceForAppointment(s.id, s.quantity)));
  };
  const CheckFieldsToUpdateAndUpdateLocalCustomer = <T extends Customer>(
    customerToUpdate: Customer,
    customerToFind: Customer,
    fieldSelector: Array<(obj: T) => any>
  ): boolean => {
    let i = fieldSelector.length - 1;
    let result = false;
    let newResult = false;
    if (i >= 0) {
      switch (nameof(fieldSelector[i])) {
        case nameof<Customer>(c => c.email):
          if (customerToFind.email && customerToFind.email.toLowerCase() !== customerToUpdate.email?.toLowerCase()) {
            customerToUpdate.email = customerToFind.email;
            result = true;
          }
          break;
        case nameof<Customer>(c => c.phoneNumber):
          if (customerToFind.phoneNumber && customerToFind.phoneNumber !== customerToUpdate.phoneNumber) {
            customerToUpdate.phoneNumber = customerToFind.phoneNumber;
            result = true;
          }
          break;
        default:
          throw `This field selector is not implemented : ${fieldSelector}`;
      }
      i--;
      newResult = i >= 0 && CheckFieldsToUpdateAndUpdateLocalCustomer(customerToUpdate, customerToFind, [fieldSelector[i]]);
      result = result || newResult;
    }
    return result;
  };
  const GetAndUpdateCustomer = async (customerToFind: Customer): Promise<Customer> => {
    const customers = await findCustomers(customerToFind.name);
    // Customer founded with email and phone number
    let customer = customers.find(
      c => c.email?.toLowerCase() === customerToFind.email?.toLowerCase() && c.phoneNumber === customerToFind.phoneNumber
    );
    if (customer) {
      return customer;
    }
    // Customer founded with email
    customer = customers.find(c => c.email?.toLowerCase() === customerToFind.email?.toLowerCase());
    if (customer) {
      // Check if we need to update phone number
      if (CheckFieldsToUpdateAndUpdateLocalCustomer(customer, customerToFind, [c => c.phoneNumber])) {
        updateDocument(GetInstanceOf(customer, CollectionPath.Customer));
      }
      return customer;
    }
    // Customer founded with phone number
    customer = customers.find(c => c.phoneNumber === customerToFind.phoneNumber);
    if (customer) {
      // Check if we need to update email
      if (CheckFieldsToUpdateAndUpdateLocalCustomer(customer, customerToFind, [c => c.email])) {
        updateDocument(GetInstanceOf(customer, CollectionPath.Customer));
      }
      return customer;
    }
    const newCustomer = new Customer(
      customerToFind.name,
      customerToFind.countryCode?.toUpperCase(),
      customerToFind.phoneNumber,
      customerToFind.email,
      undefined,
      customerToFind.note
    );
    // Create customer
    const cId = await addDocument(newCustomer);
    newCustomer.id = cId;
    return newCustomer;
  };
  const GetEndDateTime = (startDateTime: number, duration: number) => moment(startDateTime).add(duration, "second").valueOf();
  const AddAppointment = async (values: any): Promise<string> => {
    const selectedServices = GetSelectedServices(services);
    const totalPrice = GetSelectedServicesPrice(selectedServices);
    const customer = await GetAndUpdateCustomer(values as Customer);
    setBookedCustomers([customer]);
    if (!customer) {
      alert(t("ErrorSaveAppointmentCustomer"));
      return "";
    }
    const startDateTime = (values as IAppointment)?.startDateTime;
    const endDateTime = GetEndDateTime(startDateTime, GetSelectedServicesDuration(selectedServices));
    const service = ConvertToAppointmentServices(selectedServices)?.[0];
    const note = (values as IAppointment)?.note;
    const { messageDates, messageContents } = getMessageDatesAndContents(
      userId,
      startDateTime,
      endDateTime,
      customer,
      service,
      defaultMessages?.[0],
      totalPrice,
      note
    );
    const appointment = new Appointment(
      startDateTime,
      endDateTime,
      [customer.id ?? ""],
      [service],
      [defaultMessages?.map(a => a.id ?? "")?.[0]],
      messageDates,
      messageContents,
      totalPrice,
      note
    );

    return addDocument(appointment).then(id => {
      try {
        // todo USE ENV
        if (notification?.appointment?.created?.push) {
          fetch("https://europe-west1-previsy-1f5c2.cloudfunctions.net/sendPushNotification", {
            method: "POST",
            body: JSON.stringify({
              fcmTokens: userDetails.fcmTokens,
              data: GetPushAppointmentData(t, region.language, {
                eventType: PushAppointmentType.New,
                customerName: customer.name,
                serviceList: selectedServices,
                startDateTime: startDateTime,
              }),
            }),
            headers: { "Content-Type": "application/json" },
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        return id;
      }
    });
  };
  // Regex to validate phone number
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const paddingX = 5;
  return (
    <StyleProvider value={{ foregroundColor: businessDetails.foregroundColor, backgroundColor: businessDetails.backgroundColor }}>
      <MaterialUiThemeProvider>
        {isLoading ? (
          <LoadingScreen />
        ) : userId ? (
          <MainView>
            <GlobalStateContext.Provider
              value={{
                services: { value: services, setValue: setServices },
                businessDetails: { value: businessDetails, setValue: setBusinessDetails },
                calendarOption: { value: calendarOption, setValue: setCalendarOption },
              }}>
              <CustomCarousel />
              {/* First part */}
              <Grid container paddingX={paddingX}>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <LogoAndBusinessName />
                </Grid>
              </Grid>
              {/* Second part */}
              <Grid container spacing={3} paddingX={paddingX}>
                <Grid item xs={12} md={4} lg={3}>
                  {businessDetails?.description && <About />}
                  {businessDetails?.businessHours && <BusinessHours />}
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                  <BookAppointmentCard>
                    <FormikStepper
                      isCompleted={!!bookedAppointment?.id}
                      initialValues={{
                        startDateTime: moment().valueOf(),
                        name: "",
                        countryCode: currentLocale,
                        phoneNumber: undefined,
                        email: "",
                        note: "",
                      }}
                      onSubmit={async values => {
                        setSubmitted(true);
                        const id = await AddAppointment(values);
                        if (id) {
                          history.push({
                            pathname: window.location.pathname,
                            search: `?appointmentId=${id}`,
                          });
                        }
                      }}>
                      <FormikStep
                        innerRef={serviceFormRef} // Todo remove this for multiple services (see commit)
                        hideContinue // Todo remove this for multiple services (see commit)
                        canContinue={AtLeastOneServiceSelected(services)}
                        label={t("Services")}
                        labelTitle={t("SelectOneOrMoreServices")}
                        value={() => {
                          const bookedServices = services
                            ?.filter(s =>
                              bookedAppointment?.services
                                ?.filter(bs => bs.quantity > 0)
                                .map(r => r.id)
                                .includes(s.id)
                            )
                            .map(item => {
                              return {
                                ...item,
                                quantity: bookedAppointment?.services?.find(s => s.id === item.id)?.quantity ?? 0,
                              } as ServiceWithQuantity;
                            });
                          return DisplaySelectedServices(
                            bookedAppointment?.id ? bookedServices : GetSelectedServices(services),
                            region.currency,
                            region.language,
                            t
                          );
                        }}>
                        <Service
                          onSubmit={() => {
                            serviceFormRef?.current?.handleSubmit(); // Todo remove this for multiple services (see commit)
                          }}
                        />
                      </FormikStep>

                      <FormikStep
                        innerRef={calendarFormRef}
                        hideContinue
                        label={t("DateAndTime")}
                        labelTitle={t("SelectDateTime")}
                        value={() =>
                          moment(
                            bookedAppointment?.id
                              ? bookedAppointment.startDateTime
                              : (calendarFormRef.current?.values as IAppointment)?.startDateTime
                          )
                            .format("LLLL")
                            .toString()
                        }>
                        <Calendar
                          appointments={appointments}
                          onSubmit={value => {
                            calendarFormRef.current?.setFieldValue("startDateTime", value);
                            calendarFormRef?.current?.handleSubmit();
                          }}
                          businessHours={businessDetails.businessHours}
                          serviceDuration={GetSelectedServicesDuration(GetSelectedServices(services))}
                        />
                      </FormikStep>
                      <FormikStep
                        innerRef={customerInformationFormRef}
                        canContinue
                        label={t("YourInformation")}
                        labelTitle={t("EnterContactDetails")}
                        validationSchema={object({
                          name: Yup.string().required(t("NameIsRequired")),
                          phoneNumber: Yup.string().required(t("PhoneNumberIsRequired")).matches(phoneRegExp, t("EnterValidPhoneNumber")),
                          email: Yup.string().required(t("EmailIsRequired")).email(t("EnterValidEmail")),
                        })}
                        value={() =>
                          DisplayCustomerInformation(
                            bookedAppointment?.id ? bookedCustomers : [customerInformationFormRef.current?.values as ICustomer],
                            t
                          )
                        }>
                        {bookedAppointment?.id ? <Confirmation /> : <CustomerInformation />}
                      </FormikStep>
                    </FormikStepper>
                  </BookAppointmentCard>
                </Grid>
                <Grid item xs={12} md={12} lg={3}>
                  {Object.values(businessDetails?.address ?? {}).some(v => v) && <Address value={businessDetails?.address} />}
                  {businessDetails?.additionalInformation && <AdditionalInformation />}
                  {hasBusinessContact() && <Contact />}
                </Grid>
              </Grid>
              <Footer />
            </GlobalStateContext.Provider>
          </MainView>
        ) : (
          <NotFoundScreen />
        )}
      </MaterialUiThemeProvider>
    </StyleProvider>
  );
};

const BookAppointmentCard = ({ children }: ChildrenType) => {
  const { CardCtx } = useContext(StyleContext);
  return <CardCtx title="BookAppointment" avatar={<EventAvailableIcon />} children={children} />;
};

const MainView = ({ children }: ChildrenType) => {
  const { backgroundColorCtx } = useContext(StyleContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        backgroundColor: backgroundColorCtx,
      }}
      children={children}
    />
  );
};

const MaterialUiThemeProvider = ({ children }: any): any => {
  const { foregroundColorCtx, backgroundColorCtx } = useContext(StyleContext);
  return <ThemeProvider theme={theme(foregroundColorCtx, backgroundColorCtx)} children={children} />;
};

export default HomeScreen;
