import { List, ListItem, TextField, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { PickersDay, PickersDayProps } from "@material-ui/lab";
import AdapterMoment from "@material-ui/lab/AdapterMoment";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import StaticDatePicker from "@material-ui/lab/StaticDatePicker";
import * as Moment from "moment";
import { unitOfTime } from "moment";
import { extendMoment } from "moment-range";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SetOnlyDateOfMoment } from "../helpers/Helper";
import { Appointment } from "../model/classes/Appointment";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";
import { IBusinessHour } from "../model/interfaces/IBusinessHour";
interface Props {
  businessHours: IBusinessHour[];
  serviceDuration: number;
  appointments: Appointment[];
  onSubmit: (startDateTime: number) => void;
}

// Render day
const RenderDay = (day: Moment.Moment, selectedDates: (Moment.Moment | null)[], pickersDayProps: PickersDayProps<Moment.Moment>) => {
  const { foregroundColorCtx, backgroundColorCtx } = useContext(StyleContext);
  return (
    <PickersDay
      {...pickersDayProps}
      style={
        //  Selected days
        selectedDates[0]?.isSame(day, "day")
          ? {
              backgroundColor: foregroundColorCtx,
              fontWeight: "bold",
              color: "white",
            }
          : // Available days
          !pickersDayProps.disabled
          ? {
              backgroundColor: backgroundColorCtx,
              fontWeight: "bold",
              color: foregroundColorCtx,
            }
          : // Unavailable days
            {}
      }
    />
  );
};

// Calendar
const Calendar = ({ businessHours, serviceDuration, appointments, onSubmit }: Props) => {
  const { foregroundColorCtx, backgroundColorCtx } = useContext(StyleContext);
  const moment = extendMoment(Moment);
  // Locale/useRef
  const { t } = useTranslation();
  const mounted = useRef(false);
  // State
  const [isLoading, setIsLoading] = useState(true);
  const [availableHoursPerDay, setAvailableHoursPerDay] = useState<{ [key: string]: Moment.Moment[] }>({});
  const [selectedDate, setSelectedDate] = useState<Moment.Moment | null>(moment());
  const [currentMonthSelected, setCurrentMonthSelected] = useState<Moment.Moment | null>(selectedDate);
  // Variables
  const timeSlotSize = 1800; // 30min
  const pauseAfterAppointment = 900; // 15min
  const dateNowWithoutSecond = moment().second(0).millisecond(0);
  const startDateValueFromFirestore = 1; // todo ne pas autoriser les valeurs null ou zéro pour les minutes,day,month,year. c'est déja géré par le "anytime"
  const startDateValueGranularityFromFirestore = "anytime";
  const endDateValueFromFirestore = 5;
  const endDateValueGranularityFromFirestore = "anytime";
  // todo add variable for "0"
  const startDateCanBook =
    startDateValueGranularityFromFirestore !== "anytime"
      ? dateNowWithoutSecond.clone().add(startDateValueFromFirestore, startDateValueGranularityFromFirestore)
      : undefined;
  const endDateCanBook =
    endDateValueGranularityFromFirestore !== "anytime"
      ? dateNowWithoutSecond.clone().add(endDateValueFromFirestore, endDateValueGranularityFromFirestore)
      : undefined;
  // Use effect
  useEffect(() => {
    if (!mounted.current) {
      // ComponentDidMount
      mounted.current = true;
    } else {
      // ComponentDidUpdate
      // Call update available hours only in did update because use effect is async
      // So, did mount came after did udapte with appointments : []
    }
    // ComponentDidMount AND ComponentDidUpdate
    UpdateAvailableHours(selectedDate);
  }, [appointments]);

  // Check that the date meets the reservation rules defined by the user
  const isUnderBookingPolicy = (date: Moment.Moment, granularity: unitOfTime.StartOf) =>
    (!startDateCanBook || date.isSameOrAfter(startDateCanBook, granularity)) &&
    (!endDateCanBook || date.isSameOrBefore(endDateCanBook, granularity));
  //
  const doesNotOverlapsWithAppointments = (startDateTime: Moment.Moment, endDateTime: Moment.Moment) =>
    !appointments?.some(a => {
      const rangeCurrentAppointment = moment.range(
        moment(a.startDateTime).add(-pauseAfterAppointment, "second"),
        moment(a.endDateTime).add(pauseAfterAppointment, "second")
      );
      const rangeFuturAppointment = moment.range(startDateTime, endDateTime);
      return rangeCurrentAppointment.overlaps(rangeFuturAppointment);
    });
  //
  const getFuturEndTimeAppointment = (businessStartTime: Moment.Moment) => businessStartTime.clone().add(serviceDuration, "second");

  // Update available hours
  const UpdateAvailableHours = (date: Moment.Moment | null) => {
    setIsLoading(true);
    const newAvailableHoursPerDay: { [key: string]: Moment.Moment[] } = {};
    const currentDate = date?.clone().startOf("month");
    // Loop through the current month
    while (currentDate?.isSameOrBefore(date?.clone().endOf("month"), "day")) {
      // Start to looking from start date chosen by the user
      if (isUnderBookingPolicy(currentDate, "day")) {
        // Sort by startTime and after by endTime
        const currentDayBusinessHour = businessHours.find(b => b.enabled && b.day === currentDate.day())?.hours || [];
        // Ne marche pas car la sousctraction des deux date ne donne pas -1 mais un nombre plus grand alors qu'il faut -1, 1 ou 0 => a supp
        // .sort((a, b) =>
        // moment(a.startTime).diff(b.startTime) === 0 ? moment(a.endTime).diff(b.endTime) : moment(a.startTime).diff(b.startTime)
        // ) ||

        // Get available hour for each slot
        for (const businessSlot of currentDayBusinessHour) {
          const businessStartTime = SetOnlyDateOfMoment(moment(businessSlot.startTime), currentDate);
          const businessEndTime = SetOnlyDateOfMoment(moment(businessSlot.endTime), currentDate);
          // Loop through the current each time
          while (getFuturEndTimeAppointment(businessStartTime).isSameOrBefore(businessEndTime, "minute")) {
            const futurEndTimeAppointment = getFuturEndTimeAppointment(businessStartTime);
            if (
              businessStartTime.isSameOrAfter(moment(), "minute") &&
              isUnderBookingPolicy(businessStartTime, "minute") &&
              doesNotOverlapsWithAppointments(businessStartTime, futurEndTimeAppointment)
            ) {
              const currentDateString: string = currentDate.toString();
              if (!newAvailableHoursPerDay[currentDateString]) {
                newAvailableHoursPerDay[currentDateString] = [];
              }
              // Keep clone otherwise we will have duplicate values.
              const availableHour = businessStartTime.clone();
              // If not already exist. Set a new available hour.
              if (!newAvailableHoursPerDay[currentDateString].some((a: Moment.Moment) => a.isSame(availableHour, "minute"))) {
                newAvailableHoursPerDay[currentDateString].push(availableHour);
              }
            }
            // Go to next interval
            businessStartTime.add(timeSlotSize, "second");
          }
        }
      }
      // Go to next day
      currentDate.add(1, "days");
    }
    // Performance : Keep the old time available and update it with the most recent one for the new date range of the current month or
    // if an update comes from an appointment
    setAvailableHoursPerDay({
      ...availableHoursPerDay,
      ...newAvailableHoursPerDay,
    });
    setIsLoading(false);
  };
  // Render
  return (
    <Container
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
      maxWidth="xl">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* dateLibInstance={moment} */}
        <StaticDatePicker
          autoFocus
          minDate={startDateCanBook}
          maxDate={endDateCanBook}
          disablePast
          // Disable only loaded date => so only the current month because we load by month or the selected date
          // Otherwise we can't change the year for example
          shouldDisableDate={date =>
            (date?.isSame(currentMonthSelected, "month") === true || date?.isSame(selectedDate, "month") === true) &&
            !isLoading &&
            !availableHoursPerDay[date.toString()]
          }
          // date.isSame(selectedDate, "month") && !
          // availableHoursPerDay[date] === null || availableHoursPerDay[date] === []
          onYearChange={date => {
            setCurrentMonthSelected(date);
            UpdateAvailableHours(date);
          }}
          onMonthChange={date => {
            setCurrentMonthSelected(date);
            UpdateAvailableHours(date);
          }}
          renderInput={props => <TextField {...props} />}
          renderDay={RenderDay}
          showToolbar={false}
          value={selectedDate}
          onChange={
            newDate => {
              setSelectedDate(newDate);
              setCurrentMonthSelected(newDate);
            }
            // Uncomment this if showDaysOutsideCurrentMonth is enabled
            // if (newDate.isAfter(selectedDate, "month")) {
            //   UpdateAvailableHours(newDate);
            // }
          }
        />
      </LocalizationProvider>
      {/* Display available hours */}
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
        <Typography
          style={{
            display: "inline-block",
            marginTop: 18,
            marginBottom: 8,
            alignItems: "flex-end",
          }}>
          {t("BookOn")} {selectedDate?.format("LLLL").replace(selectedDate.format("LT"), "")}
        </Typography>
        {!isLoading && (
          <List sx={{ overflow: "auto", maxHeight: 300 }} dense={false}>
            {(selectedDate &&
              availableHoursPerDay[selectedDate.startOf("day").toString()]?.map(day => (
                <ListItem
                  button
                  key={day.format("HH:mm")}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: 50,
                    width: 230,
                    margin: 1,
                    borderRadius: 1,
                    border: "1px solid",
                    borderColor: backgroundColorCtx,
                    backgroundColor: "white",
                    color: foregroundColorCtx,
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "white",
                      borderColor: foregroundColorCtx,
                      border: "2px solid",
                    },
                  }}
                  onClick={() => onSubmit(day?.valueOf())}>
                  {day.format("HH:mm")}
                </ListItem>
              ))) ?? (
              <Typography
                style={{
                  display: "inline-block",
                  fontWeight: "bold",
                  textAlign: "center",
                }}>
                {t("NoTimesAvailable")}
              </Typography>
            )}
          </List>
        )}
      </div>
    </Container>
  );
};

export default Calendar;
