import moment from "moment";
import { TFunction } from "react-i18next";
import { ServiceWithQuantity } from "../model/classes/ServiceWithQuantity";

export enum PushAppointmentType {
  New,
  Updated,
  Canceled,
}
export const GetPushAppointmentData = (
  t: TFunction<"translation">,
  locale: string,
  data: {
    eventType: PushAppointmentType;
    customerName: string;
    serviceList: ServiceWithQuantity[];
    startDateTime: number;
    message?: string;
  }
) => {
  const { eventType, customerName, serviceList, startDateTime, message } = data;
  const serviceListName = serviceList.map(s => s.name).join(", ");
  const date = new Date(startDateTime).toLocaleDateString(locale, { month: "long", day: "numeric" });
  const time = moment(startDateTime).format("LT");

  if ((Number(eventType) as PushAppointmentType) === PushAppointmentType.New) {
    return {
      title: t("NewAppointment"),
      body: `${customerName} ${t("ScheduledAnAppointmentWithYouFor")} ${serviceListName} ${t("On")} ${date} ${t("At")} ${time}.`,
    };
  } else if ((Number(eventType) as PushAppointmentType) === PushAppointmentType.Updated) {
    return {
      title: t("AppointmentUpdated"),
      body: `${customerName} ${t("RescheduledTheAppointmentWithYouFor")} ${serviceListName}. ${t("NewTime")}: ${date} ${t("At")} ${time}.`,
    };
  } else {
    return {
      title: t("AppointmentCanceled"),
      body: `${customerName} ${t("CanceledTheAppointmentWithYouFor")} ${serviceListName} ${t("On")} ${date} ${t("At")} ${time}.${
        message ? " Message: " + message : ""
      }`,
    };
  }
};
