import React, { createContext, ProviderProps, useContext } from "react";
import { IsNullOrEmptyOrWhiteSpace } from "../../../helpers/Helper";
import { createTheme } from "@material-ui/core/styles";
import { IStyleContext } from "./IStyleContext";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CustomCardType } from "../../types/CustomCardType";

export const CustomCardStyle = {
  marginTop: 16,
  marginBottom: 8,
};

export const CustomCard = ({ title, avatar: Avatar, children, cardContentStyle }: CustomCardType) => {
  const { t } = useTranslation();
  const { foregroundColorCtx } = useContext(StyleContext);
  return (
    <Card style={CustomCardStyle}>
      <CardHeader
        style={{
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
          borderBottomColor: "#eeeeee",
        }}
        avatar={Avatar}
        title={t(title)}
        titleTypographyProps={{ variant: "h6", color: foregroundColorCtx }}
      />
      <CardContent style={cardContentStyle} children={children} />
    </Card>
  );
};

const defaultStyle = (fgdColor?: string, bgdColor?: string) => {
  const foregroundColor = !fgdColor || IsNullOrEmptyOrWhiteSpace(fgdColor) ? "#1380C8" : fgdColor;
  const backgroundColor = !bgdColor || IsNullOrEmptyOrWhiteSpace(bgdColor) ? "#e8f4fd" : bgdColor;
  return {
    CardCtx: CustomCard,
    foregroundColorCtx: foregroundColor,
    backgroundColorCtx: backgroundColor,
  };
};

export const theme = (fgdColor?: string, bgdColor?: string) => {
  const foregroundColor = fgdColor ?? defaultStyle().foregroundColorCtx;
  const backgroundColor = bgdColor ?? defaultStyle().backgroundColorCtx;

  return createTheme({
    palette: {
      primary: {
        main: foregroundColor,
        light: foregroundColor,
        dark: foregroundColor,
      },
      action: {
        active: foregroundColor,
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: foregroundColor,
          },
        },
      },
    },
  });
};

export const StyleContext = createContext<IStyleContext>(defaultStyle());

type Props = { foregroundColor: string; backgroundColor: string };

export const StyleProvider = ({ value, children }: ProviderProps<Props>) => {
  const style = defaultStyle(value.foregroundColor, value.backgroundColor);
  return (
    <StyleContext.Provider
      value={{
        CardCtx: style.CardCtx,
        foregroundColorCtx: style.foregroundColorCtx,
        backgroundColorCtx: style.backgroundColorCtx,
      }}
      children={children}
    />
  );
};
