import firebase from "./config";
import { IWhere } from "./IWhere";

export class Where implements IWhere {
  fieldPath: string | firebase.firestore.FieldPath;
  opStr: firebase.firestore.WhereFilterOp;
  value: any;

  constructor(fieldPath: string | firebase.firestore.FieldPath, opStr: firebase.firestore.WhereFilterOp, value: any) {
    this.fieldPath = fieldPath;
    this.opStr = opStr;
    this.value = value;
  }
}
