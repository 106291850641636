import moment from "moment";
import "moment-duration-format";
import "moment/min/locales";
import { useTranslation } from "react-i18next";
import { CollectionPath } from "../api/firebase/Types/CollectionPath";
import { defaultLanguage } from "../i18n";
import { Appointment } from "../model/classes/Appointment";
import { Customer } from "../model/classes/Customer";
import { IDocument } from "../model/interfaces/IDocument";

// Set only the time of a date
export const SetOnlyTime = (date: Date, newTime: Date) => {
  const tempDate = new Date(date);
  tempDate.setHours(newTime.getHours());
  tempDate.setMinutes(newTime.getMinutes());
  // Make sure the seconds and milliseconds are empty
  tempDate.setSeconds(0, 0);
  return tempDate;
};
// Set only the date of a date
export const SetOnlyDate = (date: Date, newDate: Date) => {
  const tempDate = new Date(date);
  tempDate.setFullYear(newDate.getFullYear());
  tempDate.setMonth(newDate.getMonth());
  tempDate.setDate(newDate.getDate());
  return tempDate;
};
// Set only the date of a moment
export const SetOnlyDateOfMoment = (oldMoment: moment.Moment, newMoment: moment.Moment) => {
  let tempMoment = oldMoment.clone();
  tempMoment = tempMoment.year(newMoment.year());
  tempMoment = tempMoment.month(newMoment.month());
  tempMoment = tempMoment.date(newMoment.date());
  return tempMoment;
};
// Get current locale
export const GetCurrentLocale = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language.toLowerCase();
  const locales = moment.locales()?.map(l => l.toLowerCase());
  if (locales?.includes(currentLocale)) {
    return currentLocale;
  }
  const currentLocaleSplited = currentLocale?.split("-")[0];
  if (locales?.includes(currentLocaleSplited)) {
    return currentLocaleSplited;
  }
  // No current locale found, return english
  return defaultLanguage;
};
// Return an instance of the interface
export const GetInstanceOf = (data: IDocument, collectionPath: CollectionPath) => {
  let typedData;
  switch (collectionPath) {
    case CollectionPath.Appointment:
      typedData = data as Appointment;
      return new Appointment(
        typedData.startDateTime,
        typedData.endDateTime,
        typedData.customerIds,
        typedData.services,
        typedData.messageIds,
        typedData.messageDates,
        typedData.messageContents,
        typedData.price,
        typedData.note,
        typedData.id
      );
    case CollectionPath.Customer:
      typedData = data as Customer;
      return new Customer(
        typedData.name,
        typedData.countryCode,
        typedData.phoneNumber,
        typedData.email,
        typedData.address,
        typedData.note,
        typedData.id
      );
    // case CollectionPath.Service:
    //   typedData = data as IService;
    //   return new Service(typedData.name, typedData.price, typedData.duration);
    // case CollectionPath.Message:
    //   typedData = data as IMessage;
    //   return new Message(typedData.name, typedData.sendingTimes, typedData.template, typedData.isDefault);
    default:
      throw `This collection type is not implemented : ${collectionPath}`;
  }
};
export const IsNullOrEmptyOrWhiteSpace = (value: string) => {
  return !value || value.toString().match(/^ *$/) !== null;
};
export const ConvertToCurrenyFormat = (value: number, locales: string, currency: string) =>
  value || value === 0
    ? new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(Number(value?.toString()?.replaceAll(",", ".")))
    : value?.toString();
