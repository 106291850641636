import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { deleteAppointment } from "../api/firebase/FirestoreService";

const Confirmation = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  console.log("search ", search);
  const history = useHistory();
  const CustomButton = ({ text, onClick, inherit = false }: { text: string; onClick: () => void; inherit?: boolean }) => (
    <Button
      style={{ marginBottom: 12, textTransform: "none" }}
      variant="contained"
      color={inherit ? "inherit" : "primary"}
      type="submit"
      onClick={onClick}>
      {text}
    </Button>
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <Typography style={{ fontSize: 24, marginTop: 15, marginBottom: 40, color: "#159e4c", fontWeight: "bold" }}>
        {t("AppointmentConfirmationMessage")}
      </Typography>
      <Grid container style={{ alignItems: "center", justifyContent: "center" }}>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <CustomButton
            text={t("BookNewAppointment")}
            onClick={() => {
              history.push({
                pathname: window.location.pathname,
                search: "",
              });
            }}
          />
          {/* <CustomButton text={t("EditNewAppointment")} onClick={() => ""} />*/}
          {/* <CustomButton text={t("CancelNewAppointment")} inherit onClick={() => deleteAppointment("")} /> */}
        </Grid>
      </Grid>
    </div>
  );
};
export default Confirmation;
