import { ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import "moment-timezone";
import "moment/min/locales";
import { Provider } from "react-redux";
import "./App.scss";
import { GetCurrentLocale } from "./helpers/Helper";
import { theme } from "./model/interfaces/contexts/StyleContext";
import store from "./redux/Store";
import Routes from "./routers/Routes";

const App = () => {
  // useEffect(() => {
  //   // ComponentDidMount
  //   moment.locale(GetCurrentLocale());
  // }, []);
  moment.locale(GetCurrentLocale());

  return (
    <ThemeProvider theme={theme()}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </ThemeProvider>
  );
};
export default App;
