import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import AddCircleOutlineSharpIcon from "@material-ui/icons/AddCircleOutlineSharp";
import RemoveCircleOutlineSharpIcon from "@material-ui/icons/RemoveCircleOutlineSharp";
import moment from "moment";
import "moment-duration-format";
import "moment/min/locales";
import React, { useContext } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { ServiceWithQuantity } from "../model/classes/ServiceWithQuantity";
import { RootState, useAppSelector } from "../redux/Store";
import { GlobalStateContext } from "./screens/HomeScreen";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";
import { ConvertToCurrenyFormat, IsNullOrEmptyOrWhiteSpace } from "../helpers/Helper";

export const AtLeastOneServiceSelected = (services: ServiceWithQuantity[]) => services?.some(s => s.quantity > 0) == true;

export const GetSelectedServices = (services: ServiceWithQuantity[]) => services?.filter(s => s.quantity > 0);

export const GetSelectedServicesDuration = (selectedServices: ServiceWithQuantity[]) =>
  selectedServices?.map(s => (s.quantity ?? 0) * (s.duration ?? 0))?.reduce((acc, duration) => duration + acc, 0) ?? 0;

export const GetSelectedServicesPrice = (selectedServices: ServiceWithQuantity[]) =>
  selectedServices?.map(s => (s.quantity ?? 0) * (s.price ?? 0)).reduce((acc, price) => acc + price, 0) ?? 0;

const DisplayServiceDuration = (seconds: number) =>
  seconds ? moment.duration(seconds, "seconds").format("H[h] m[m]", { trim: "both" }) : "";

const DisplayServicePrice = (price: number, currency: string, language: string, t: TFunction<"translation">) => {
  return `${price === null || price === undefined ? "" : price ? `${ConvertToCurrenyFormat(price, language, currency)}` : t("Free")}`;
};

const DisplayService = (seconds: number, price: number, currency: string, language: string, t: TFunction<"translation">) => {
  let durationString = DisplayServiceDuration(seconds);
  let priceString = DisplayServicePrice(price, currency, language, t);
  return `${durationString} ${
    (!IsNullOrEmptyOrWhiteSpace(durationString) && !IsNullOrEmptyOrWhiteSpace(priceString) ? " | " : "") + priceString
  }`;
};

export const DisplaySelectedServices = (
  selectedServices: ServiceWithQuantity[],
  currency: string,
  language: string,
  t: TFunction<"translation">
) => {
  if (selectedServices) {
    if (selectedServices?.length > 0) {
      return `${`${selectedServices.length > 1 ? t("MultipleSelectedServices") : selectedServices[0].name} | `} ${DisplayService(
        GetSelectedServicesDuration(selectedServices),
        GetSelectedServicesPrice(selectedServices),
        currency,
        language,
        t
      )}`;
    } else return t("NoService");
  }
  return "";
};
interface Props {
  onSubmit: () => void;
}
export const Service = ({ onSubmit }: Props) => {
  const { services } = useContext(GlobalStateContext);
  const { backgroundColorCtx } = useContext(StyleContext);
  const region = useAppSelector((state: RootState) => state.region);
  const { t } = useTranslation();
  const { foregroundColorCtx } = useContext(StyleContext);
  // Update services state
  const UpdateServicesQuantity = (service: ServiceWithQuantity, quantity: number) => {
    // Mutate state - Solution 1 :
    let newServices = services?.value.map(item => {
      return item.id === service.id && quantity >= 0
        ? ({
            ...item,
            quantity: quantity,
          } as ServiceWithQuantity)
        : ({ ...item, quantity: 0 } as ServiceWithQuantity); // todo remove this to enable multiple service. rollback by item
      // : item;
    });
    if (newServices) {
      services?.setValue(newServices);
    }
    // Mutate state - Solution 2 :
    // let newServices = [...services];
    // let index = newServices.findIndex(s => s.id === service.id);
    // if (index >= 0 && quantity >= 0) {
    //   // Need to copy nested object
    //   let s = { ...newServices[index] } as Service;
    //   s.quantity = quantity;
    //   newServices[index] = s;
    //   setServices(newServices);
    // }
  };

  const UpdateServicesQuantityByOne = (service: ServiceWithQuantity, add: boolean) =>
    UpdateServicesQuantity(service, (service.quantity ?? 0) + (add ? 1 : -1));

  return (services?.value?.length ?? 0) > 0 ? (
    <List style={{ overflow: "auto", maxHeight: 375 }} dense={false}>
      {services?.value?.map(service => {
        const isSelected = service.quantity > 0;
        return (
          <ListItem
            button={true}
            sx={{
              backgroundColor: isSelected ? backgroundColorCtx : "white",
              color: foregroundColorCtx,
            }}
            selected={isSelected}
            key={service.id}
            onClick={() => {
              if ((service.quantity ?? 0) <= 0) {
                UpdateServicesQuantityByOne(service, true);
                // Todo remove this for multiple services (see commit)
                onSubmit();
              } else if (service.quantity === 1) UpdateServicesQuantityByOne(service, false);
            }}>
            <ListItemText
              style={{ marginRight: 50 }}
              primaryTypographyProps={{
                color: foregroundColorCtx,
                fontWeight: "bold",
              }}
              primary={service.name}
              secondary={DisplayService(service.duration, service.price, region.currency, region.language, t)}
            />
            {false && ( // todo remove this (false) to enable multiple service
              <ListItemSecondaryAction>
                <IconButton edge="start" aria-label="delete" onClick={() => UpdateServicesQuantityByOne(service, false)}>
                  <RemoveCircleOutlineSharpIcon />
                </IconButton>
                <label
                  style={{
                    fontWeight: 500,
                    color: foregroundColorCtx,
                  }}>
                  {service.quantity ?? 0}
                </label>
                <IconButton edge="end" aria-label="add" onClick={() => UpdateServicesQuantityByOne(service, true)}>
                  <AddCircleOutlineSharpIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  ) : (
    <Typography variant="h6" style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
      {t("NoAvailableService")}
    </Typography>
  );
};
