import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { StyleContext } from "../../model/interfaces/contexts/StyleContext";

const NotFoundScreen = () => {
  const { t } = useTranslation();
  const { foregroundColorCtx } = useContext(StyleContext);
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        paddingBottom: 100,
        backgroundColor: foregroundColorCtx,
      }}>
      <label style={{ color: "white", fontSize: 50, fontWeight: "bold" }}>{t("404ErrorTitle")}</label>
      <label style={{ color: "white", fontSize: 25 }}>{t("404ErrorMessage")}</label>
    </div>
  );
};
export default NotFoundScreen;
