import { BusinessDetails } from "../../model/classes/BusinessDetails";
import { CalendarOption } from "../../model/classes/CalendarOption";
import { INotification } from "../../model/interfaces/INotification";
import { IRegion } from "../../model/interfaces/IRegion";
import { IUserDetails } from "../../model/interfaces/IUserDetails";
import { ActionType, IStore } from "../Type";
import * as type from "../types/LocalDataType";
// Initial state
const initialState: IStore = {
  isLoading: true,
  userId: undefined,
  businessDetails: {} as BusinessDetails,
  userDetails: {} as IUserDetails,
  appointments: [],
  services: [],
  messages: [],
  calendarOption: {} as CalendarOption,
  region: {} as IRegion,
  notification: {} as INotification,
};
// Local data reducer
export const localDataReducer = (prevState = initialState, action: ActionType) => {
  switch (action.type) {
    case type.IS_LOADING:
      return {
        ...prevState,
        isLoading: action.payload,
      };
    case type.UPDATE_USER_ID:
      return {
        ...prevState,
        userId: action.payload,
      };
    case type.UPDATE_BUSINESS_DETAILS:
      return {
        ...prevState,
        businessDetails: action.payload,
      };
    case type.UPDATE_USER_DETAILS:
      return {
        ...prevState,
        userDetails: action.payload,
      };
    case type.UPDATE_APPOINTMENTS:
      return {
        ...prevState,
        appointments: action.payload,
      };
    case type.UPDATE_SERVICES:
      return {
        ...prevState,
        services: action.payload,
      };
    case type.UPDATE_DEFAULT_MESSAGES:
      return {
        ...prevState,
        messages: action.payload,
      };
    case type.UPDATE_CALENDAR_OPTION:
      return {
        ...prevState,
        calendarOption: action.payload,
      };
    case type.UPDATE_REGION:
      return {
        ...prevState,
        region: action.payload,
      };
    case type.UPDATE_NOTIFICATION:
      return {
        ...prevState,
        notification: action.payload,
      };
    default:
      return prevState;
  }
};
