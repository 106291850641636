import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { localDataReducer } from "./reducers/LocalDataReducer";
import { UPDATE_APPOINTMENTS } from "./types/LocalDataType";
// Store (Unique global state)
const store = configureStore({
  reducer: localDataReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Disable warning for date field
      serializableCheck: {
        ignoredActions: [UPDATE_APPOINTMENTS],
      },
    }),
});
// Export type for typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Typed hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
