import Typography from "@material-ui/core/Typography";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { useContext } from "react";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";
import { GlobalStateContext } from "./screens/HomeScreen";

const AdditionalInformation = () => {
  const { businessDetails } = useContext(GlobalStateContext);
  const { CardCtx } = useContext(StyleContext);
  return (
    <CardCtx title="AdditionalInformation" avatar={<EmojiObjectsOutlinedIcon />}>
      <Typography variant="body2" whiteSpace="pre-line" style={{ wordWrap: "break-word" }}>
        {businessDetails?.value?.additionalInformation}
      </Typography>
    </CardCtx>
  );
};
export default AdditionalInformation;
