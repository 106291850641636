import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeScreen from "../components/screens/HomeScreen";
import NotFoundScreen from "../components/screens/NotFoundScreen";
// Routes
const Routes = () => (
  <Router history={createBrowserHistory()}>
    <Switch>
      <Route path="/:urlName" component={HomeScreen} />
      <Route component={NotFoundScreen} />
    </Switch>
  </Router>
);
export default Routes;
