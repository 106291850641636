import { IsNullOrEmptyOrWhiteSpace } from "../../helpers/Helper";
import { Appointment } from "../../model/classes/Appointment";
import { BusinessDetails } from "../../model/classes/BusinessDetails";
import { CalendarOption } from "../../model/classes/CalendarOption";
import { Customer } from "../../model/classes/Customer";
import { Document } from "../../model/classes/Document";
import { Message } from "../../model/classes/Message";
import { Notification } from "../../model/classes/Notification";
import { Region } from "../../model/classes/Region";
import { ServiceWithQuantity } from "../../model/classes/ServiceWithQuantity";
import { UserDetails } from "../../model/classes/UserDetails";
import store from "../../redux/Store";
import firebase from "./config";
import { ConverterBase } from "./ConverterBase";
import { IWhere } from "./IWhere";
import { CollectionPath } from "./Types/CollectionPath";
import { Where } from "./Where";

//#region Private
// Get user collection
const getUserCollection = () => firebase.firestore().collection(CollectionPath.User).doc(store.getState().userId);

// Get documents
const getDocuments = async <T extends Document>(collectionType: CollectionPath, where?: IWhere): Promise<T[]> => {
  try {
    let collectionRef;
    if (where) {
      collectionRef = getUserCollection().collection(collectionType).where(where.fieldPath, where.opStr, where.value);
    } else {
      collectionRef = getUserCollection().collection(collectionType);
    }
    const snapshot = await collectionRef.withConverter(ConverterBase<T>()).get();
    return snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  } catch (e) {
    alert(e);
    return null!;
  }
};
//#endregion

//#region Public
// Get user Id from booking url
export const getUserIdFromBookingUrl = async (bookingUrl: string) => {
  if (IsNullOrEmptyOrWhiteSpace(bookingUrl)) {
    return "";
  }
  let businessDetails = await firebase
    .firestore()
    .collectionGroup(CollectionPath.Option)
    .where("bookingUrl", "in", [bookingUrl, bookingUrl.toLowerCase()])
    .get()
    .catch(e => {
      alert(e);
      return null;
    });
  // if ((businessDetails?.docs?.length ?? 0) > 1) {
  //   // todo envoyé une erreur au serveur
  // }
  return businessDetails?.docs?.[0]?.ref?.parent?.parent?.id ?? "";
};
// Get customer
export const GetCustomer = (id: string) => {
  return getDocumentData<Customer>(CollectionPath.Customer, id);
};

// Find customers
export const findCustomers = async (name: string) => {
  return getDocuments<Customer>(CollectionPath.Customer, new Where("nameLowerCase", "==", name));
};

// Get all services
export const getAllServices = async () => {
  const services = await getDocuments<ServiceWithQuantity>(CollectionPath.Service);
  // Re-order by 'name' because firestore doesn't manage case sensitive
  return services?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
};

// Get default messages
export const getDefaultMessages = async () => {
  const messages = await getDocuments<Message>(CollectionPath.Message, new Where("isDefault", "==", true));
  return messages;
};

// Listen appointments
export const listenAppointments = <T extends Appointment>(setAppointments: (appointments: T[]) => void) => {
  try {
    const collection = getUserCollection().collection(CollectionPath.Appointment);
    return collection.withConverter(ConverterBase<T>()).onSnapshot(
      collectionSnapshot => {
        setAppointments(collectionSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      },
      e => {
        alert(e);
      }
    );
  } catch (e) {
    alert(e);
    return null;
  }
};
// Add document
export const addDocument = async <T extends Document>(document: T): Promise<string> => {
  try {
    return await getUserCollection()
      .collection(document.getCollectionPath())
      .withConverter(ConverterBase<T>())
      .add(document)
      .then(d => {
        return d.id;
      })
      .catch(e => {
        alert(e);
        return "";
      });
  } catch (e) {
    alert(e);
  }
  return "";
};

// Update document
export const updateDocument = <T extends Document>(document: T): void => {
  try {
    getUserCollection()
      .collection(document.getCollectionPath())
      .doc(document.id)
      //.withConverter(ConverterBase<T>()) // Not supported by firebase for Update
      .update(ConverterBase<T>().toFirestore(document))
      .catch(e => {
        alert(e);
      });
  } catch (e) {
    alert(e);
  }
};

// Get document data
export const getDocumentData = async <T extends Document>(collectionPath: string, id: string) => {
  try {
    const snapshot = await getUserCollection().collection(collectionPath).withConverter(ConverterBase<T>()).doc(id).get();
    return snapshot.data();
  } catch (e) {
    alert(e);
    return null!;
  }
};

export const getUserDetails = async () => {
  try {
    const snapshot = await getUserCollection().withConverter(ConverterBase<UserDetails>()).get();
    return snapshot.data();
  } catch (e) {
    alert(e);
    return undefined;
  }
};

export const getBusinessDetails = async () => await getDocumentData<BusinessDetails>(CollectionPath.Option, CollectionPath.BusinessDetails);

export const getCalendarOptions = async () => await getDocumentData<CalendarOption>(CollectionPath.Option, CollectionPath.Calendar);

export const getRegion = async () => await getDocumentData<Region>(CollectionPath.Option, CollectionPath.Region);

export const deleteAppointment = (id: string) => getUserCollection().collection(CollectionPath.Appointment).doc(id).delete();

export const getNotification = async () => await getDocumentData<Notification>(CollectionPath.Option, CollectionPath.Notification);

//#endregion
