import { Grid } from "@material-ui/core";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";

// Footer
const Footer = () => {
  const { t } = useTranslation();
  const { foregroundColorCtx } = useContext(StyleContext);
  return (
    <Grid
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
      }}>
      <Grid item>
        <label style={{ fontWeight: "500", color: foregroundColorCtx }}>{t("PoweredByPrevisy")}</label>
      </Grid>
    </Grid>
  );
};
export default Footer;
