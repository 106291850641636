import { IconButton } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { ContactsOutlined, Twitter } from "@material-ui/icons";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from "@material-ui/icons/Language";
import MailIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/PhoneOutlined";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RiSnapchatLine } from "react-icons/ri";
import { SiTiktok } from "react-icons/si";
import { StyleContext } from "../model/interfaces/contexts/StyleContext";
import { GlobalStateContext } from "./screens/HomeScreen";

// Contact button
const ContactButton = props => {
  const { name, icon, baseUrl, value } = props;
  return (
    <IconButton
      aria-label={name}
      component="span"
      onClick={() => {
        window.open(baseUrl + value, "_blank", "noopener,noreferrer");
      }}>
      {icon}
      <Link component="button" underline="hover" color="primary" style={{ marginLeft: 15, textAlign: "left" }} variant="body2">
        {value}
      </Link>
    </IconButton>
  );
};
// Contact
const Contact = () => {
  const { t } = useTranslation();
  const { businessDetails } = useContext(GlobalStateContext);
  const { CardCtx } = useContext(StyleContext);
  // create value props to reduce number of lines display on vs code
  const v = businessDetails?.value;
  return (
    <CardCtx
      title="Contact"
      avatar={<ContactsOutlined />}
      cardContentStyle={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "flex-start",
      }}>
      {v?.phoneNumber && <ContactButton icon={<PhoneIcon />} baseUrl="tel:" value={v.phoneNumber} name={t("PhoneNumber")} />}
      {v?.email && <ContactButton icon={<MailIcon />} baseUrl="mailto:" value={v.email} name={t("Email")} />}
      {v?.website && (
        <ContactButton
          icon={<LanguageIcon />}
          baseUrl={v.website?.startsWith("https://") || v.website?.startsWith("http://") ? "" : "https://"}
          value={v.website}
          name={t("WebSite")}
        />
      )}
      {v?.facebook && <ContactButton icon={<FacebookIcon />} baseUrl="https://facebook.com/" value={v.facebook} name="Facebook" />}
      {v?.instagram && <ContactButton icon={<InstagramIcon />} baseUrl="https://instagram.com/" value={v.instagram} name="Instagram" />}
      {v?.twitter && <ContactButton icon={<Twitter />} baseUrl="https://twitter.com/" value={v.twitter} name="Twitter" />}
      {v?.snapchat && <ContactButton icon={<RiSnapchatLine />} baseUrl="https://snapchat.com/add/" value={v.snapchat} name="Snapchat" />}
      {v?.tiktok && <ContactButton icon={<SiTiktok />} baseUrl="https://tiktok.com/" value={v.tiktok} name="Tiktok" />}
    </CardCtx>
  );
};
export default Contact;
