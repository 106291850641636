import { Card, CardContent, CardMedia, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useContext } from "react";
import { CustomCardStyle, StyleContext } from "../model/interfaces/contexts/StyleContext";
import { GlobalStateContext } from "./screens/HomeScreen";

const logoStyle = { minWidth: 230, minHeight: 230 };

const Logo = ({ logoUrl, hasCarouselPhoto }) => {
  const { backgroundColorCtx } = useContext(StyleContext);
  return hasCarouselPhoto ? (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        width: logoStyle.minWidth,
      }}>
      <Card
        style={{
          position: "absolute",
          marginLeft: -5,
          marginBottom: -5,
          zIndex: 1,
          boxShadow: "none",
          borderWidth: 5,
          borderStyle: "solid",
          borderColor: backgroundColorCtx,
          borderBottomRightRadius: 0,
        }}>
        <CardMedia sx={logoStyle} image={logoUrl} />
      </Card>
    </div>
  ) : (
    <CardMedia sx={logoStyle} image={logoUrl} />
  );
};

const LogoAndBusinessName = () => {
  const { businessDetails } = useContext(GlobalStateContext);
  const { foregroundColorCtx, backgroundColorCtx } = useContext(StyleContext);
  const HasBusinessPhoto = () => businessDetails?.value?.photosUrls?.filter(p => p).length > 0;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      item
      style={{
        display: isSmallScreen ? "inline-block" : "flex",
        flex: 1,
        flexDirection: isSmallScreen ? "column" : "row",
        justifyContent: "flex-start",
        alignItems: isSmallScreen ? "center" : "flex-end",
        marginTop: !HasBusinessPhoto() && CustomCardStyle.marginTop + CustomCardStyle.marginBottom,
        marginBottom: CustomCardStyle.marginBottom,
      }}>
      <div style={{ display: "flex", justifyContent: isSmallScreen ? "center" : "flex-start" }}>
        {businessDetails?.value?.logoUrl && (
          <Logo isSmallScreen={isSmallScreen} logoUrl={businessDetails?.value?.logoUrl} hasCarouselPhoto={HasBusinessPhoto()} />
        )}
      </div>

      <div style={{ display: "flex", flex: 1, justifyContent: isSmallScreen ? "center" : "flex-start" }}>
        {businessDetails?.value?.name && (
          <Card
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "flex-start",
              minWidth: logoStyle.minWidth,
              boxShadow: "none",
              borderRadius: 0,
              borderTop: 5,
              borderTopStyle: "solid",
              borderTopColor: backgroundColorCtx,
              zIndex: 2,
            }}>
            <CardContent style={{ display: "flex", flex: 1 }}>
              <Typography
                color={foregroundColorCtx}
                fontWeight="bold"
                variant="h4"
                style={{
                  display: "flex",
                  flex: 1,
                  wordWrap: "break-word",
                  justifyContent: isSmallScreen ? "center" : "flex-start",
                }}>
                {businessDetails?.value?.name}
              </Typography>
            </CardContent>
          </Card>
        )}
      </div>
    </Grid>
  );
};
export default LogoAndBusinessName;
