import "reflect-metadata";

const prefixMetadataKey = "custom:";

export const DateTimeConverter = (target: Object, propertyKey: string | symbol) => {
  Reflect.defineMetadata(`${prefixMetadataKey}${DateTimeConverter.name}`, DateTimeConverter.name, target, propertyKey);
};

export const DateTimeArrayConverter = (target: Object, propertyKey: string | symbol) => {
  Reflect.defineMetadata(`${prefixMetadataKey}${DateTimeArrayConverter.name}`, DateTimeArrayConverter.name, target, propertyKey);
};

export const GetCustomDecorators = (target: any, propertyName: string | symbol): string[] => {
  // get info about keys that used in current property
  const keys: any[] = Reflect.getMetadataKeys(target, propertyName);
  const decorators = keys
    // filter your custom decorators
    .filter(key => key.toString().startsWith(prefixMetadataKey))
    .reduce((values, key) => {
      // get metadata value.
      const currValues = Reflect.getMetadata(key, target, propertyName);
      return values.concat(currValues);
    }, []);

  return decorators;
};

export const FindCustomDecorator = (target: any, propertyName: string | symbol, customDecoratorName: string): string | undefined =>
  GetCustomDecorators(target, propertyName).find(cd => cd === customDecoratorName);

// export function Example(limit: number) {
//   return function (target: Object, propertyKey: string) {
//     let value: string;
//     const getter = function () {
//       return value;
//     };
//     const setter = function (newVal: string) {
//       if (newVal.length < limit) {
//         Object.defineProperty(target, "errors", {
//           value: `Your password should be bigger than ${limit}`,
//         });
//       } else {
//         value = newVal;
//       }
//     };
//     Object.defineProperty(target, propertyKey, {
//       get: getter,
//       set: setter,
//     });
//   };
// }
