import firebase from "firebase";
// Config from firebase console
const config = {
  apiKey: "AIzaSyBs70f4nh3DEgPzTUtb9G_3QWxZpxbgHNU",
  authDomain: "previsy-1f5c2.firebaseapp.com",
  projectId: "previsy-1f5c2",
  storageBucket: "previsy-1f5c2.appspot.com",
  messagingSenderId: "809633976725",
  appId: "1:809633976725:web:68b5c6caf93a91a5af669c",
  measurementId: "G-RPED7H40VQ",
};
// Check if not already initialize
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
// Ignore undefined properties
firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true });
export default firebase;
