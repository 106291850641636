import { Button, CircularProgress, Grid, IconButton, Step, StepLabel, Stepper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Form, Formik, FormikConfig, FormikValues } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleContext } from "../../model/interfaces/contexts/StyleContext";
import { FormikStepProps } from "./FormikStepProps";

type myProps = { isCompleted: boolean };
export default function FormikStepper({ isCompleted, children, ...props }: FormikConfig<FormikValues> & myProps) {
  const { t } = useTranslation();
  const { foregroundColorCtx } = useContext(StyleContext);
  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChildren = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const [childrenValues, setChildrenValues] = useState(childrenArray.map(c => c.props.value()));
  const currentChildrenValue = currentChildren?.props?.value?.();

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  const UpdateChildrenValues = () => {
    let copy = [...childrenValues];
    copy[step] = currentChildren?.props?.value?.();
    setChildrenValues(copy);
  };

  // Update state when we have an appointment Id or we are on the last step
  useEffect(() => {
    if (completed != isCompleted) {
      if (isCompleted) {
        setStep(childrenArray?.length - 1);
      } else {
        setStep(0);
      }
    }
    setCompleted(isCompleted);
    UpdateChildrenValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted, childrenArray?.length, currentChildrenValue]);

  return (
    <Formik
      {...props}
      innerRef={currentChildren.props.innerRef}
      validationSchema={currentChildren.props.validationSchema}
      onSubmit={async (values, helpers) => {
        UpdateChildrenValues();
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep(s => s + 1);
          // the next line was not covered in the youtube video
          //
          // If you have multiple fields on the same step
          // we will see they show the validation error all at the same time after the first step!
          //
          // If you want to keep that behavior, then, comment the next line :)
          // If you want the second/third/fourth/etc steps with the same behavior
          //    as the first step regarding validation errors, then the next line is for you! =)
          //
          // In the example of the video, it doesn't make any difference, because we only
          //    have one field with validation in the second step :)
          helpers.setTouched({});
        }
      }}>
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "flex-start" }}>
            {step > 0 && (
              <Grid item style={{ position: "absolute", zIndex: 1, marginTop: -7, marginLeft: -5 }}>
                {!completed && (
                  <IconButton disabled={isSubmitting} color="primary" onClick={() => setStep(s => s - 1)}>
                    <ArrowBackIcon />
                  </IconButton>
                )}
              </Grid>
            )}

            <Stepper alternativeLabel activeStep={step} style={{ display: "flex", flex: 1, marginBottom: 15 }}>
              {childrenArray.map((child, index) => (
                <Step key={child.props.label} completed={step > index || completed}>
                  <StepLabel>
                    <Typography style={{ color: "9E9E9E" }}>{child.props.label}</Typography>
                    <Typography style={{ color: foregroundColorCtx }}>
                      {(step > index || (isLastStep() && completed)) && childrenValues[index]}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {/* <Typography style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10, marginBottom: 5 }}>
            {currentChild.props.labelTitle}
          </Typography> */}
          {currentChildren}
          {!currentChildren.props.hideContinue && !completed && (
            <Grid container style={{ marginTop: 20, marginBottom: -10, justifyContent: "flex-end" }}>
              <Grid item>
                <Button
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting || !currentChildren.props.canContinue}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {isLastStep() ? t("BookAppointment") : t("Continue")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
}
