import { DateTimeArrayConverter, DateTimeConverter, FindCustomDecorator } from "../../model/Decorators";
import { IDocument } from "../../model/interfaces/IDocument";
import firebase from "./config";

// TODO : move this to previsy framework
export const ConverterBase = <T extends IDocument>() => ({
  toFirestore: (data: T) => {
    // Copy data without document Id
    let newData: { [k: string]: any } = { ...data, id: undefined };
    Object.keys(data).forEach(key => {
      if (FindCustomDecorator(data, key, DateTimeConverter.name)) {
        newData[key] = newData[key] ? new Date(newData[key]) : newData[key];
      } else if (FindCustomDecorator(data, key, DateTimeArrayConverter.name)) {
        newData[key] = (newData[key] as [])?.map(n => (n ? new Date(n) : n));
      }
    });
    return newData;
  },
  // fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as T,
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): T {
    const data = snapshot.data();
    const converted = Object.keys(data).reduce<firebase.firestore.DocumentData>((accumulator, key) => {
      // Timestamp
      if (data[key] instanceof firebase.firestore.Timestamp) {
        accumulator[key] = (data[key] as firebase.firestore.Timestamp).seconds * 1000;
      }
      // Array of timestamp
      else if (Array.isArray(data[key]) && data[key].length > 0 && data[key][0] instanceof firebase.firestore.Timestamp) {
        accumulator[key] = data[key].map((d: firebase.firestore.Timestamp) => d.seconds * 1000);
      }
      // Other type
      else {
        accumulator[key] = data[key];
      }
      return accumulator;
    }, {});
    return converted as T;
    // var classA = activator(Document);
    // var sd = classA instanceof Document;
    // var s = classA instanceof Customer;
    // classA = converted as T;
    // var s = classA instanceof Customer;
    // let d = new T();
    // return converted as IDocument;
    // return classA as T;
  },
});
// function activator<T extends IDocument>(type: { new (): T }): T {
//   return new type();
// }
